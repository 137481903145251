import React from "react";
import Image from "web/components/atoms/Image";
import RichTextComponent from "web/components/atoms/RichText/RichText";
import classNames from "classnames";

const TextImage = ({ content }) => {
  if (!content) return;

  const {
    text,
    block_size,
    bg_color = "transparent",
    text_color = "inherit",
    text_align = "left",
    padding_top = 0,
    padding_bottom = 0,
    image,
    floating_image,
    image_position,
    content_vertical_align,
  } = content.primary;

  const style = {
    color: text_color,
    backgroundColor: bg_color,
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
    textAlign: text_align,
  };

  const classes = classNames({
    "block-text-image": true,
    floating: floating_image,
  });

  return (
    <>
      <div
        className={`${classes} ${image_position} ${content_vertical_align}`}
        style={style}
      >
        <div className={`block-text-image__inner ctn ctn-${block_size}`}>
          <div className="block-text-image__image">
            <Image image={image} />
          </div>
          <div className="block-text-image__text richtext">
            <RichTextComponent content={text} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextImage;
