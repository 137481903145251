import React from "react";
import RichTextComponent from "web/components/atoms/RichText/RichText";
import classNames from "classnames";

const AccordionListItem = ({ item }) => {
  const [active, setActive] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState(0);

  const handleClick = () => {
    setActive(!active);
  };

  const contentRef = React.useRef(null);

  React.useEffect(() => {
    setMaxHeight(active ? `${contentRef.current.scrollHeight}px` : 0);
  }, [active]);

  const tabClass = classNames({
    "block-accordion-list__item": true,
    active: active,
  });

  const contentStyles = {
    maxHeight: maxHeight,
  };

  return (
    <div className={tabClass}>
      <div onClick={handleClick} className="block-accordion-list__item__label">
        {item.label}
      </div>
      <div
        className="block-accordion-list__item__content"
        style={{ ...contentStyles }}
        ref={contentRef}
      >
        <div className="block-accordion-list__item__content__inner">
          <blockquote>
            <RichTextComponent content={item.content} />
          </blockquote>
        </div>
      </div>
    </div>
  );
};

const AccordionList = ({ content }) => {
  const { items, primary } = content;

  const {
    title,
    block_size,
    bg_color = "transparent",
    text_color = "inherit",
    padding_top = 0,
    padding_bottom = 0,
  } = primary;

  const style = {
    color: text_color,
    backgroundColor: bg_color,
    paddingTop: padding_top,
    paddingBottom: padding_bottom,
  };
  return (
    <div className="block-accordion-list" style={style}>
      <div className={`block-accordion-list__inner ctn ctn-${block_size}`}>
        <h2>{title}</h2>
        <div className="block-accordion-list__items">
          {items.map((item, key) => {
            return <AccordionListItem key={key} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AccordionList;
