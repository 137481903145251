import RichText from "web/modules/Prismic/slices/RichText";
import Cards from "web/modules/Prismic/slices/Cards";
import Separator from "web/modules/Prismic/slices/Separator";
import Images from "web/modules/Prismic/slices/Images";
import TextImage from "web/modules/Prismic/slices/TextImage";
import ImagesSlider from "web/modules/Prismic/slices/ImagesSlider";
import Reviews from "web/modules/Prismic/slices/Reviews";
import Reinsurance from "web/modules/Prismic/slices/Reinsurance";
import Presentation from "web/modules/Prismic/slices/Presentation";
import ContactForm from "web/modules/Prismic/slices/ContactForm";
import NewsLetter from "web/modules/Prismic/slices/NewsLetter";
import AccordionList from "web/modules/Prismic/slices/AccordionList";
import Video from "web/modules/Prismic/slices/Video";

export default {
  richtext: {
    Slice: RichText,
  },
  cards: {
    Slice: Cards,
  },
  separator: {
    Slice: Separator,
  },
  images: {
    Slice: Images,
  },
  textimage: {
    Slice: TextImage,
  },
  imagesslider: {
    Slice: ImagesSlider,
  },
  reviews: {
    Slice: Reviews,
  },
  reinsurance: {
    Slice: Reinsurance,
  },
  presentation: {
    Slice: Presentation,
  },
  contactform: {
    Slice: ContactForm,
  },
  newsletter: {
    Slice: NewsLetter,
  },
  accordionlist: {
    Slice: AccordionList,
  },
  video: {
    Slice: Video,
  },
};
